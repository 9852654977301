<template>
  <div class="step-card" :style="'border-left-color:' + step.color">
    <a-row type="flex" justify="space-between">
      <a-col class="name"> {{ step.name }} </a-col>

      <a-col>
        <a-icon type="edit" @click="onClickOpenEditStepModal(step.id)" />
      </a-col>
    </a-row>
    <div class="description">{{ step.description }}</div>

    <a-modal
      width="700px"
      v-model="openEditStepModal"
      :footer="false"
      @cancel="onCloseEditStepModal"
    >
      <div slot="title">Editar Etapa - ID {{ editId }}</div>
      <EditFunnelStepModal v-if="openEditStepModal" :editId="editId" :funnelId="funnelId" />
    </a-modal>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import EditFunnelStepModal from "../modals/EditFunnelStepModal.vue";

export default {
  name: "StepCard",
  components: { EditFunnelStepModal },
  props: {
    step: Object,
    funnelId: String
  },
  mixins: [formatThings],
  data() {
    return {
      openEditStepModal: false,
      editId: "",
    };
  },
  mounted() {},
  methods: {
    onClickOpenEditStepModal(id) {
      this.openEditStepModal = true;
      this.editId = id;
    },
    onCloseEditStepModal() {
      this.openEditStepModal = false;
      this.editId = "";
      this.$emit("updateStepsList");
    },
  },
};
</script>

<style lang="sass" scoped>
.step-card
  border: 1px solid #d6d6d6
  border-width: 1px 1px 1px 3px
  position: relative
  background: #f5f7fa
  font-size: 12px
  padding: 8px 6px
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 5px
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1)
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1)
  border-radius: 6px
  .name
    font-weight: 600
    font-size: 12px
    color: #333

  .description
    padding: 5px 0 0
    color: #999
    font-size: 10px
</style>
