<template>
  <div>
    <div class="funnel">
      <a-row type="flex" justify="space-between">
        <a-col>
          <h2>{{ funnel.id }} - {{ funnel.name }}</h2>
        </a-col>
        <a-col class="actions">
          <a-tooltip v-if="funnel.description" placement="top">
            <template slot="title">
              <span>{{ funnel.description }}</span>
            </template>
            <a-icon type="info-circle" />
          </a-tooltip>

          <a-icon
            type="edit"
            @click="onClickOpenEditFunnelModal(funnel.id)"
            class="ml-10"
          />

          <a-popconfirm
            title="Tem certeza que deseja apagar o funil?"
            ok-text="Sim"
            placement="top"
            cancel-text="Não"
            @confirm="confirmDelete(funnel.id)"
          >
            <a class="red"> <a-icon type="delete" class="ml-10" /> </a>
          </a-popconfirm>
        </a-col>
      </a-row>

      <div class="count-steps">
        Funil com {{ opportunitiesFunnelSteps.list.length }} etapas
      </div>

      <div class="add-step">
        <a-input-search
          v-model="funnelStepName"
          placeholder="Nova etapa"
          enter-button="Add Etapa"
          @search="onClickAddStep"
        />
      </div>

      <div class="mb-10 f12">
        <a @click="showSteps('Ativado')">Ativos</a><a-divider type="vertical" />
        <a @click="showSteps('Desativado')">Desativados</a>
      </div>
      <vueCustomScrollbar :settings="settings">
        <a-row class="steps">
          <draggable
            v-model="opportunitiesFunnelSteps.list"
            group="steps"
            @start="startDrag"
            @end="endDrag"
          >
            <a-col
              class="mb-10"
              :span="24"
              v-for="(step, index) in opportunitiesFunnelSteps.list"
              :key="index"
            >
              <div>
                <StepCard
                  @updateStepsList="updateStepsList"
                  :editId="step.id"
                  :funnelId="funnel.id"
                  :step="step"
                />
              </div>
            </a-col>
          </draggable>

          <div class="f12" v-if="opportunitiesFunnelSteps.list.length === 0">
            Nenhuma etapa.
          </div>
        </a-row>
      </vueCustomScrollbar>
    </div>

    <a-modal
      width="700px"
      v-model="openEditFunnelModal"
      :footer="false"
      @cancel="onCloseEditFunnelModal"
    >
      <div slot="title">Editar Funil - ID {{ editId }}</div>
      <EditFunnelModal v-if="openEditFunnelModal" :editId="editId" />
    </a-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import vueCustomScrollbar from "vue-custom-scrollbar";

import opportunitiesFunnelStepsMixins from "../mixins/opportunitiesFunnelStepsMixins";
import EditFunnelModal from "../modals/EditFunnelModal.vue";
import StepCard from "./StepCard.vue";

export default {
  name: "FunnelCard",
  props: {
    funnel: Object,
    loading: Boolean,
  },
  components: { draggable, EditFunnelModal, StepCard, vueCustomScrollbar },
  mixins: [opportunitiesFunnelStepsMixins],
  data() {
    return {
      funnelStepName: "",
      editId: "",
      openEditFunnelModal: false,
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: true,
      },
    };
  },
  created() {
    this.$on("reloadFunnelSteps", this.handleEvent);
  },
  mounted() {
    this.opportunitiesFunnelSteps.filters.funnelId = this.funnel.id;
    this.opportunitiesFunnelSteps.filters.status.selected = ["Ativado"];

    this.getOpportunitiesFunnelSteps();
  },
  methods: {
    handleEvent() {
      this.opportunitiesFunnelSteps.list = [];

      setTimeout(() => {
        this.getOpportunitiesFunnelSteps();
      }, 10000);
    },
    showSteps(status) {
      this.opportunitiesFunnelSteps.filters.status.selected = [status];
      this.getOpportunitiesFunnelSteps();
    },
    updateStepsList() {
      console.log("updateStepsList");
      this.getOpportunitiesFunnelSteps();
    },
    onClickOpenEditFunnelModal(id) {
      this.editId = id;
      this.openEditFunnelModal = true;
    },
    onCloseEditFunnelModal() {
      this.editId = "";
      this.openEditFunnelModal = false;
      this.getOpportunitiesFunnelSteps();
      this.$emit("updateFunnelsList");
    },
    confirmDelete(id) {
      this.$http
        .post(`/opportunity-funnels/delete?id=${id}`)
        .then(({ data }) => {
          this.$message.success(data.message + "!");
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: id,
            module: "opportunity-funnels",
            action: "delete-opportunity-funnel",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} apagou o funil ID ${id}.`,
          });
          this.$emit("updateFunnelsList");
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    startDrag(evt) {
      console.log(evt, this.opportunitiesFunnelSteps.list);
    },
    endDrag(evt) {
      evt;
      this.opportunitiesFunnelSteps.list.forEach((step, index) => {
        this.$http
          .post("/opportunities-funnel-steps/update-field", {
            id: step.id,
            field: "step_order",
            value: index,
          })
          .then(({ data }) => {
            data;
            if (step.step_order != index) {
              this.$http.post("/log/create", {
                user_id: this.$store.state.userData.id,
                module_id: step.id,
                module: "opportunities-funnel-steps",
                action: "change-step-position",
                description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} mudou a posição da etapa <b>${step.name}</b> de ${step.step_order} para ${index}`,
              });
            }
          });
      });
    },
    onClickAddStep() {
      if (this.funnelStepName) {
        this.$http
          .post("/opportunities-funnel-steps/create", {
            user_id: this.$store.state.userData.id,
            funnel_id: this.funnel.id,
            status: "Ativado",
            name: this.funnelStepName,
            visibility: "Em aberto",
            modified_by: {
              name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
              id: this.$store.state.userData.id,
            },
          })
          .then(({ data }) => {
            this.$message.success(data.message);

            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: data.id,
              module: "opportunities-funnel-steps",
              action: "create-step",
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} criou uma nova etapa para o funil ID ${this.funnel.id}.`,
            });

            this.funnelStepName = "";
            this.getOpportunitiesFunnelSteps();
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
          });
      } else {
        this.$message.warning("Escreva um nome para a etapa.");
      }
    },
  },
};
</script>

<style>
.scroll-area,
.ps-container,
.ps {
  position: relative;
  margin: auto;
  width: 100%;
  overflow: auto;
  height: 380px;
  padding: 0 15px 0 0;
}
</style>

<style lang="sass" scoped>
.funnel
  height: 500px
  position: relative
  padding: 8px 16px
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1)
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1)
  border-right: 1px solid #d6d6d6
  h2
    margin: 0
    font-size: 16px
    font-weight: 600
  .count-steps
   font-size: 12px
  .add-step
    font-size: 14px
    font-weight: 600
    margin: 20px 0

  .step
    position: relative
    background: #f5f7fa
    font-size: 12px
    padding: 8px 6px
    box-shadow: rgba(0, 0, 0, 0.05) 0 0 5px
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1)
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1)
    border-radius: 6px
    border: 1px solid #d6d6d6
</style>
